import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import ServiceHero from "../components/service-hero";
import CTA from "../components/cta-bar";
import * as Markdown from "react-markdown";
import Card from "../components/card";
import PhotoGalleryComponent from "../components/photo-gallery-component";
import SocialReviewsFullwidth from "../components/social-reviews-fullwidth";
import { getSchemaSameAs } from "../helpers/schema-json";
import { CheckCircle } from "react-feather";
import FAQ from "../components/FAQ";
import RecentBlogPosts from "../components/RecentBlogPosts";
import RoofingCalculator from "../components/sidebars/roof-calc";


class ServiceCityTemplate extends Component {
  render() {
    function parseContent(string, altCity) {
      if (string) {
        const newString = string.replace(/{{city}}/g, altCity ? altCity : city);
        const secondReplacement = newString.replace(
          /{{citySlug}}/g,
          (altCity ? altCity : city).replace(/\./g, "").toLowerCase().replace(/\s/g, "-") + "-ca"
        );

        return secondReplacement;
      }
    }
    let { pageContext } = this.props;
    const city = pageContext.city;
    let pageData = this.props.data.contentfulServiceAreaContent;
    let citiesList = this.props.data.allContentfulServiceArea.edges;
    let content = parseContent(pageData.content.content);
    let tidbits = "";
    tidbits = pageContext.tidbits;

    //replace city in keywords array
    if (pageData.metaKeywordsList) {
      pageData.metaKeywordsList.forEach(function (keyword, index) {
        pageData.metaKeywordsList[index] = parseContent(keyword);
      });
    }

    const TopBar = () => {
      const topBar = pageData.topbar || [];
      return topBar.map(({ id, cssClasses, content }) => (
        <section key={id} className={cssClasses} dangerouslySetInnerHTML={{ __html: content.content }} />
      ));
    };

    const SidebarColumn = () => {
      const sideBarCards = pageData.sidebar.map((sidebar, index) => {
        if (sidebar.__typename == "ContentfulSidebar") {
          return <Card key={index} sidebar={sidebar} />;
        } else if (sidebar.__typename == "ContentfulSidebarCollection") {
          const innerCards = sidebar.sidebars.map((sidebar, index) => {
            return <Card key={"inner" + index} sidebar={sidebar} />;
          });
          return innerCards;
        } else return null;
      });

      return sideBarCards;
    };
    const schemaSameAs = getSchemaSameAs(this.props.data.site.siteMetadata.social);

    const getSubtitle = () => {
      const { textLink, slug, repairSubtitle } = pageContext

      if (slug === 'roofing' && textLink) return textLink

      if (slug === 'roofing-repair' && repairSubtitle) return repairSubtitle

      return parseContent(pageData?.excerpt)
    }

    const getContent = () => {
      const { roofingContent, slug } = pageContext

      if (slug === 'roofing' && roofingContent) return roofingContent

      return parseContent(pageData.content.content)
    }

    return (
      <>
        <Layout pageProps={this.props} footerLogoShowcase={pageData.footerLogoShowcase} customSchema>
          <SEO
            title={parseContent(pageData.seoTitle)}
            description={parseContent(pageData.metaDescription)}
            keywords={pageData.metaKeywordsList ? pageData.metaKeywordsList : []}
          />
          <ServiceHero
            pageData={pageData}
            serviceTitle={parseContent(pageData.serviceTitle)}
            heroImage={pageData.heroImage}
            excerpt={getSubtitle()}
            pageProps={this.props}
            badges={pageData.headerFeaturedBadges}
            specialOffer={parseContent(pageData.specialOffer)}
            offerDisclaimer={parseContent(pageData.offerDisclaimer)}
          />
          
          {pageData.socialReviewsFullWidth && pageData.socialReviewsFullWidth.slug ? (
            <SocialReviewsFullwidth modalLinkTo={`/reviews/${pageData.socialReviewsFullWidth.slug}/`} />
          ) : null}

          {pageData.photoGalleryRef && pageData.photoGalleryRef.photos ? (
            <PhotoGalleryComponent gallery={pageData.photoGalleryRef} />
          ) : null}
      <div className="rounded-2xl overflow-hidden max-w-7xl mx-auto relative px-8 py-20"> <RoofingCalculator/></div>
          {<TopBar />}

          <div>
            <div className="flex flex-wrap  px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto py-8 md:py-12">
              <div className={`${pageData.sidebar ? "md:w-2/3 md:pr-4" : "w-full"} prose md:prose-lg`}>

                {content && (
                  <div dangerouslySetInnerHTML={{ __html: getContent() }} />
                )}
                
                <div className="pt-10 pb-5 bg-white rounded-2xl">
                  <span className="px-8 block text-2xl font-bold text-neutral-900 font-display">Other Service Areas</span>
                  <ul className="flex flex-wrap list-none m-0">
                    {citiesList.map((item, i) => {
                      return (
                        <li key={i} className="w-1/2 md:w-1/3 flex space-y-4 md:space-y-6">
                          <div className="flex-shrink-0 mr-2">
                            <CheckCircle fontWeight={900} strokeWidth={3} size={20} className="text-brand-500 mt-2" />
                          </div>{" "}
                          <Link
                            style={{ marginTop: 0, textDecoration: "none" }}
                            className="text-neutral-800 underline-none"
                            title={`${parseContent(pageData.serviceTitle, item.node.location)}`}
                            to={`/${pageData.slug}-${item.node.slug}/`}
                          >
                            {item.node.location}
                            <span className="block text-sm font-normal text-neutral-500">{pageData.serviceName}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              {pageData.sidebar ? (
                <div className="w-full md:w-1/3 md:pl-4 space-y-4 md:space-y-6">
                   <Card sidebar={{ content: { content: "{{Finacing}}" } }} />
                  <SidebarColumn />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  ">
            <div className="mt-4 mb-8 prose">
              <h4>About {city}</h4>
              <Markdown children={tidbits} />
            </div>
          </div>

          <div className="bg-gradient-to-b from-neutral-100 to-white">
      <div className="max-w-7xl mx-auto px-4 md:px-6 py-12 md:py-20">
          <RecentBlogPosts/>
          </div>
          </div>
          <FAQ classnames={'-mb-12'}/>
          <CTA
            title={"Start Your " + parseContent(pageData.serviceTitle) + " Project Today!"}
            customTitle={parseContent(pageData.footerCallToActionHeading)}
            customDesc={parseContent(pageData.footerCallToActionDesc)}
            bgimage={pageData.footerCallToActionImg}
            serviceHeroImage={pageData.heroImage}
            btn={"Get Started!"}
            props={this.props}
          />
        </Layout>
        <script
  type="application/ld+json"
  dangerouslySetInnerHTML={{
    __html: `
    {
      "@id": "https://roachhomeimprovement.com#HomeAndConstructionBusiness",
      "@context": "https://www.schema.org",
      "@type": "HomeAndConstructionBusiness",
      "name": "Roach Home Improvement",
      "url": "https://roachhomeimprovement.com/",
      "logo": "https://images.ctfassets.net/seqn6hyrh7p7/47ugz3TDJq3S0Vlkz6QjRt/04a537cf23f52f952ef3dfdc0e144663/roachhomeimprovement-thumb.jpg",
      "image": "https://images.ctfassets.net/seqn6hyrh7p7/47ugz3TDJq3S0Vlkz6QjRt/04a537cf23f52f952ef3dfdc0e144663/roachhomeimprovement-thumb.jpg",
      "description": "Roach Home Improvement is a ${city} ${pageData.serviceTitle
      .replace(/{{city}}/g, "")
      .replace(" in ", "")
      .replace(", MI", "")} specializing in whole house remodeling and building custom homes near you.",
      "openingHours": "Mo, Tu, We, Th, Fr 08:00-18:00",
      "telephone": "+1 269-420-6623",
      "priceRange":"$$",  
      "contactPoint": {
          "@type": "ContactPoint",
          "contactType": "sales",
          "telephone": "+1 269-420-6623"
      }
    }`,
  }}
/>
<script
  type="application/ld+json"
  dangerouslySetInnerHTML={{
    __html: `
    {
      "@context": "http://schema.org/",
      "@type": "Service",
      "serviceType": "${this.props.pageContext.serviceTitle}",
      "provider": {
          "@type": "Organization",
          "name": "${this.props.data.site.siteMetadata.title}",
          "url": "${this.props.data.site.siteMetadata.siteUrl + this.props.location.pathname}",
          "logo": "https://images.ctfassets.net/seqn6hyrh7p7/47ugz3TDJq3S0Vlkz6QjRt/04a537cf23f52f952ef3dfdc0e144663/roachhomeimprovement-thumb.jpg",
          "image": "https://images.ctfassets.net/seqn6hyrh7p7/47ugz3TDJq3S0Vlkz6QjRt/04a537cf23f52f952ef3dfdc0e144663/roachhomeimprovement-thumb.jpg",
          "address": {
              "@type": "PostalAddress",
              "addressLocality": "${city}",
              "addressRegion": "CA",
              "postalCode": "${this.props.pageContext.zipCode}"
          },
          "contactPoint": {
              "@type": "ContactPoint",
              "contactType": "customer support",
              "telephone": "${this.props.data.site.siteMetadata.companyInfo.phone}",
              "email": "${this.props.data.site.siteMetadata.companyInfo.email}"
          },
          "sameAs": [${schemaSameAs.map((link) => `"${link}"`).join(", ")}]
      },
      "areaServed": [{
          "@type": "Place",
          "name": "${city}",
          "@id": "https://en.wikipedia.org/wiki/${city.replace(/\s/g, "_")},_Michigan"
      }],
      "hasOfferCatalog": {
          "@type": "OfferCatalog",
          "name": "${this.props.pageContext.serviceTitle}",
          "itemListElement": [{
              "@type": "Offer",
              "itemOffered": {
                  "@type": "Service",
                  "name": "${this.props.pageContext.serviceTitle.toLowerCase()} services"
              }
          }]
      }
    }
    `,
  }}
/>
      </>
    );
  }
}

export default ServiceCityTemplate;

export const pageQuery = graphql`
  query serviceCityQuery($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        companyInfo {
          phone
          email
        }
        social {
          guildquality
          facebook
          yelp
          instagram
          google
          houzz
        }
      }
    }
    contentfulServiceAreaContent(slug: { eq: $slug }) {
      id
      serviceTitle
      serviceName
      seoTitle
      metaDescription
      slug
      excerpt
      specialOffer
      offerDisclaimer
      metaKeywordsList

      socialReviewsFullWidth {
        slug
      }
      testimonialSection {
        testimonials {
          id
          title

          video {
            id
            title
            file {
              url
              contentType
            }
          }
          thumbnail {
            gatsbyImageData(width: 600, quality: 90, placeholder: NONE)
          }
        }
      }
      photoGalleryRef {
        id
        title
        description {
          description
        }
        photos {
          id
          title
          gatsbyImageData(width: 150, quality: 70, aspectRatio: 1.26153846154, placeholder: NONE)
          fullSize: gatsbyImageData(width: 1874, quality: 70, placeholder: NONE)
        }
      }
      headerFeaturedBadges {
        title
        gatsbyImageData(width: 300, placeholder: NONE)
      }
      heroImage {
        gatsbyImageData(width: 1200, quality: 70, placeholder: BLURRED)
      }
      content {
        content
      }
      footerLogoShowcase
      footerCallToActionHeading
      footerCallToActionDesc
      footerCallToActionImg {
        gatsbyImageData(width: 1800, placeholder: NONE)
      }
      topbar {
        id
        title
        content {
          id
          content
        }
        cssClasses
      }
      sidebar {
        __typename
        ... on ContentfulSidebar {
          id
          title
          content {
            id
            content
          }
          testimonialCategory {
            id
            name
          }
          cssClasses
        }
        ... on ContentfulSidebarCollection {
          sidebars {
            id
            title
            content {
              id
              content
            }
            testimonialCategory {
              id
              name
            }
            cssClasses
          }
        }
      }
      schemaItemOffered
    }
    allContentfulServiceArea(sort: { fields: location }) {
      edges {
        node {
          location
          slug
          marketSegment
          zipCodes
        }
      }
    }
  }
`;
